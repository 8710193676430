* { font-family: 'Syne Mono', sans-serif; }

#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  background-image: url("./images/bg4.png"), url("./images/bg.jpeg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

main {
  max-width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  width: 100%;
  max-width: 420px;
  margin: 2rem auto 0;
}

footer {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.btn-group {
  margin-bottom: 1rem;
}

.btn {
  border: 1px solid rgba(238, 0, 0, .5);
  box-shadow: 0 6px 10px 0 rgba(238, 0, 0, 0.14), 0 1px 18px 0 rgba(238, 0, 0, 0.12), 0 3px 5px -1px rgba(238, 0, 0, 0.20);
  background-color: rgba(38, 38, 38, .5);
  color: white;
  padding: 4px 6px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  font-weight: 600;
}

.btn svg {
  margin-right: 0.5rem;
}

.btn:hover {
  background-color: rgba(224, 224, 224, .16);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.audit {
  display: none;
}

.rugdoc {
  position: relative;
  width: 200px;
}

.paladin {
  position: relative;
  width: 200px;
  margin: 0 0 12px auto;
}

.defiyield {
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, .25);
  margin: 0 0 12px auto;
}

.badges {
  position: absolute;
  font-size: 10px;
  padding: 4px 8px;
  border: 1px solid #FFB74D;
  border-radius: 6px;
  color: #fff;
  text-decoration: none;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
}

.rugdoc .badges {
  top: 0;
  right: 0;
}

.paladin .badges {
  top: -12px;
  right: -6px;
}

.toolbar {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.toolbar a {
  color: #D50000;
  margin: 0 0.5rem;
}

.playing {
  color: #00ABE0 !important;
}

.playing svg {
  fill: #00ABE0 !important;
}

.audit-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, .5);
  margin: 0 -1.5rem;
}

.audit-mobile-link {
  flex: 1 0 0;
  font-size: 1rem;
  border: 1px solid #FFF;
  border-radius: 6px;
  padding: 4px 8px;
  color: #FFF;
  text-decoration: none;
  margin: 4px;
}

.low-risk {
  color: #00ABE0;
}

.kyc {
  color: #FFB52C;
}

@media (min-width: 992px) {
  main {
    max-width: 960px;
  }

  footer {
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .btn-group {
    flex: 1 0 0;
  }

  .audit {
    display: flex;
    flex: 1 0 0;
  }

  .toolbar {
    margin-bottom: 0;
  }

  .audit-mobile {
    display: none;
  }
}
